// src/pages/Home.jsx
import { Button } from "@/components/ui/button";
import { useNavigate } from "@tanstack/react-router";
import gptFactory from "../assets/gpt-factory.webp";

const Home = () => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate({ to: "/login" });
  };

  return (
    <div className="min-h-screen bg-gray-50 flex flex-col">
      {/* Hero Section */}
      <div className="relative bg-white shadow-lg">
        <div className="container mx-auto w-full flex flex-col items-center gap-4 px-6 py-16 text-center lg:text-left lg:justify-between lg:items-center">
          <div className="lg:w-2/3">
            <h1 className="text-4xl font-extrabold text-gray-900 leading-tight mb-4">
              Hub de Segurança do Trabalho
            </h1>
            <p className="text-lg text-gray-600 mb-8">
              Facilite a gestão de segurança do trabalho e reduza riscos em
              indústrias e fábricas com módulos especializados para prevenção e conformidade.
            </p>
            <Button 
              onClick={handleClick} 
              className="px-6 py-3 text-lg font-semibold"
            >
              Entrar
            </Button>
          </div>
          <div className="mt-8 lg:mt-0 lg:w-2/3">
            <img
              src={gptFactory}
              alt="Segurança Industrial"
              className="rounded-lg shadow-md"
            />
          </div>
        </div>
      </div>

      {/* Módulos Disponíveis */}
      <div className="container mx-auto px-6 py-16">
        <h2 className="text-3xl font-bold text-gray-800 text-center mb-8">
          Módulos que Oferecemos
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {[
            "Controle de EPI",
            "Treinamentos de Segurança",
            "Análise de Riscos",
            "Gestão de Conformidade",
            "Inspeções de Equipamentos",
            "Relatórios Personalizados",
          ].map((modulo, index) => (
            <div
              key={index}
              className="bg-white p-6 rounded-lg shadow-md hover:shadow-lg transition-shadow"
            >
              <h3 className="text-xl font-semibold mb-4">{modulo}</h3>
              <p className="text-gray-600">
                Um módulo especializado para melhorar a segurança e eficiência no local de trabalho.
              </p>
            </div>
          ))}
        </div>
      </div>

      {/* Rodapé */}
      <footer className="bg-gray-800 py-6">
        <div className="container mx-auto text-center text-white">
          <p>© 2024 Hub de Segurança. Todos os direitos reservados.</p>
        </div>
      </footer>
    </div>
  );
};

export default Home;
