import React, { useState, useEffect } from "react";
import {
  Sheet,
  SheetContent,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from "@/components/ui/sheet";
import {
  Select,
  SelectTrigger,
  SelectContent,
  SelectItem,
  SelectValue,
} from "@/components/ui/select";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { z } from "zod";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { createUser, updateUser } from "@/services/userService";
import { toast } from "@/hooks/use-toast";
import Loader from "@/components/general-components/Loader";
import { DialogDescription } from "@/components/ui/dialog";
import { useAuth } from "@/context/AuthContext";
import { UserPlus, Edit3, ChevronRight } from "lucide-react";
import { formatCPF, unformatCPF } from "@/utils/cpf-mask";
import { User } from "@/interfaces/user.interface";
import useVerify from "@/hooks/use-verify";
import DropUpload from "@/components/general-components/DropUpload";

const UserForm = ({ userToEdit }: { userToEdit?: User }) => {
  const queryClient = useQueryClient();
  const { user } = useAuth(); 
  const companyId = user?.companyId || 1;

  const { is } = useVerify();

  // Schema atualizado para validar image como File ou null
  const userSchema = z.object({
    name: z.string().min(2, { message: "Nome deve ter pelo menos 2 caracteres" }),
    email: z.string().email({ message: "Email inválido" }),
    phone: z.string().min(10, { message: "Telefone deve ter pelo menos 10 dígitos" }),
    cpf: z.string().length(11, { message: "CPF deve ter 11 dígitos" }),
    password: z.string().optional().or(
      z.string().min(6, { message: "Senha deve ter pelo menos 6 caracteres" })
    ),
    companyId: z.number(),
    roleId: z.number(),
    imageUrl: z.string().nullable(), // Schema atualizado para validar image como File ou null
    image: z.instanceof(File).nullable().or(z.literal(null)).refine(
      (value) => value === null || value instanceof File,
      {
        message: "Imagem deve ser um arquivo ou nulo.",
      }
    ),
  }).refine((data) => {
    if (!userToEdit && !data.password) {
      return false;
    }
    return true;
  }, {
    message: "A senha é obrigatória para novos usuários.",
    path: ["password"],
  });


  type UserFormData = z.infer<typeof userSchema>;

  // Controle de abertura do Sheet
  const [isSheetOpen, setIsSheetOpen] = useState(false);
  const [formData, setFormData] = useState<UserFormData>({
    name: "",
    email: "",
    phone: "",
    cpf: "",
    password: "",
    companyId,
    roleId: 4,
    image: null,
    imageUrl: null,
  });

  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const [preview, setPreview] = useState<string | null>(null);

  // Efeito para pré-preencher o formulário quando userToEdit for fornecido
  useEffect(() => {
    if (userToEdit) {
      setFormData({
        name: userToEdit.name || "",
        email: userToEdit.email || "",
        phone: userToEdit.phone || "",
        cpf: userToEdit.cpf || "",
        password: "",
        companyId: userToEdit.companyId || companyId,
        roleId: userToEdit.roleId || 4,
        image: null,
        imageUrl: userToEdit.imageUrl || null,
      });
      if (userToEdit.imageUrl) {
        setPreview(userToEdit.imageUrl);
      }
    }
  }, [userToEdit, companyId, isSheetOpen]);

  useEffect(() => {
    if (!userToEdit) {
      setFormData({
        name: "",
        email: "",
        phone: "",
        cpf: "",
        password: "",
        companyId,
        roleId: 4,
        image: null,
        imageUrl: null,
      });
    }
  }, []);

  const { mutate: registerUser, isPending } = useMutation({
    mutationFn: (newUser: UserFormData) => createUser(newUser),
    onSuccess: () => {
      toast({
        title: "Usuário registrado!",
        description: "Usuário criado com sucesso.",
        variant: "success",
      });
      queryClient.invalidateQueries({ queryKey: ["listCompanyUsers"] });
      // Limpa o formulário e fecha o Sheet
      setFormData({
        name: "",
        email: "",
        phone: "",
        cpf: "",
        password: "",
        companyId,
        roleId: 4,
        image: null,
        imageUrl: null,
      });
      setIsSheetOpen(false);
    },
    onError: (error: any) => {
      toast({
        title: "Erro ao registrar usuário",
        description: error.response?.data?.message || "Erro desconhecido.",
        variant: "destructive",
      });
    },
  });

  // Função para remover itens vazios do formulário
  const removeEmptyValues = (obj: any) => {
    const newObj = { ...obj };
    Object.keys(newObj).forEach((key) => {
      if (newObj[key] === "") {
        delete newObj[key];
      }
    });
    return newObj;
  };

  const { mutate: updateUserMutation, isPending: isPendingUpdate } = useMutation({
    mutationFn: (updatedUser: UserFormData) => updateUser(removeEmptyValues(updatedUser), userToEdit?.id),
    onSuccess: () => {
      toast({
        title: "Usuário atualizado!",
        description: "Usuário atualizado com sucesso.",
        variant: "success",
      });
      queryClient.invalidateQueries({ queryKey: ["listCompanyUsers"] });
      // Limpa o formulário e fecha o Sheet
      setFormData({
        name: "",
        email: "",
        phone: "",
        cpf: "",
        password: "",
        companyId,
        roleId: 4,
        image: null,
        imageUrl: null,
      });
      setIsSheetOpen(false);
    },
    onError: (error: any) => {
      toast({
        title: "Erro ao atualizar usuário",
        description: error.response?.data?.message || "Erro desconhecido.",
        variant: "destructive",
      });
    },
  });

  const handleChange = (name: string, value: string | number) => {
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    const result = userSchema.safeParse(formData);

    if (!result.success) {
      const formattedErrors: any = result.error.format();
      const newErrors: { [key: string]: string } = {};
      for (const key in formattedErrors) {
        if (key !== "_errors") {
          newErrors[key] = formattedErrors[key]?._errors[0] || "";
        }
      }
      setErrors(newErrors);
      return;
    }

    if (userToEdit) {
      updateUserMutation(formData);
    } else {
      registerUser(formData);
    }
  };

  return (
    <Sheet open={isSheetOpen} onOpenChange={setIsSheetOpen}>
      <SheetTrigger asChild>
        {
          userToEdit ? (
            <Button variant="ghost" className={`flex justify-start p-2 items-baseline w-full h-fit`}>
              <Edit3 className="w-3 h-3 mr-2"/>
              <p>Editar</p>
            </Button>
          ) : (
            <Button variant="outline" className={`flex gap-2 items-center md:items-baseline`}>
              <UserPlus className="w-3 h-3" /> <p className="hidden md:block">Novo Usuário</p>
            </Button>
          )
        }
        
      </SheetTrigger>
      <SheetContent side="right" className="w-full md:w-[400px] overflow-y-auto">
        <SheetHeader>
          <SheetTitle>{userToEdit ? `Editar Usuário ${userToEdit.name}` : "Novo Usuário"}</SheetTitle>
          <DialogDescription>
            Por favor, preencha todas as informações necessárias para {userToEdit ? "atualizar" : "registrar"} o usuário.
          </DialogDescription>
        </SheetHeader>
          <form onSubmit={handleSubmit} className="flex flex-col gap-2 mt-4">
            <DropUpload
              setImage={setFormData}
              EditPreview={preview}
            />
            <div>
              <Label htmlFor="name">Nome</Label>
              <Input
                id="name"
                name="name"
                placeholder="Digite nome do usuário"
                value={formData.name}
                onChange={(e) => handleChange(e.target.name, e.target.value)}
                className="mt-1"
              />
              {errors.name && <p className="text-red-500 text-sm">{errors.name}</p>}
            </div>
            <div>
              <Label htmlFor="email">Email</Label>
              <Input
                id="email"
                name="email"
                type="email"
                placeholder="Digite email do usuário"
                value={formData.email}
                onChange={(e) => handleChange(e.target.name, e.target.value)}
                className="mt-1"
              />
              {errors.email && <p className="text-red-500 text-sm">{errors.email}</p>}
            </div>
            <div>
              <Label htmlFor="phone">Telefone</Label>
              <Input
                id="phone"
                name="phone"
                placeholder="Digite telefone do usuário"
                value={formData.phone}
                onChange={(e) => handleChange(e.target.name, e.target.value)}
                className="mt-1"
              />
              {errors.phone && <p className="text-red-500 text-sm">{errors.phone}</p>}
            </div>
            <div>
              <Label htmlFor="cpf">CPF</Label>
              <Input
                id="cpf"
                name="cpf"
                placeholder="Digite CPF do usuário"
                value={formatCPF(formData.cpf)}
                onChange={(e) => handleChange(e.target.name, unformatCPF(e.target.value))}
                className="mt-1"
              />
              {errors.cpf && <p className="text-red-500 text-sm">{errors.cpf}</p>}
            </div>
            <div>
              <Label htmlFor="password">Senha</Label>
              <Input
                id="password"
                name="password"
                type="password"
                placeholder={userToEdit ? "Alterar senha do usuário" : "Digite senha do usuário"}
                value={formData.password}
                onChange={(e) => handleChange(e.target.name, e.target.value)}
                className="mt-1"
              />
              {errors.password && <p className="text-red-500 text-sm">{errors.password}</p>}
            </div>
            <div>
              <Label htmlFor="roleId">Função</Label>
              <Select
                onValueChange={(value) => handleChange("roleId", Number(value))}
                value={formData.roleId.toString()}
              >
                <SelectTrigger className="mt-1">
                  <SelectValue placeholder="Selecione a função" />
                </SelectTrigger>
                <SelectContent>
                  {
                    is('admin') && (
                      <SelectItem value="2">Admin</SelectItem>
                    )
                  }
                  <SelectItem value="3">Manager</SelectItem>
                  <SelectItem value="4">User</SelectItem>
                </SelectContent>
              </Select>
            </div>
            <Button
              type="submit"
              className="w-full my-4"
              disabled={isPending || isPendingUpdate}
            >
              {isPending || isPendingUpdate
                ? userToEdit
                  ? "Atualizando..."
                  : "Registrando..."
                : userToEdit
                ? "Atualizar Usuário"
                : "Registrar Usuário"}
            </Button>
            {(isPending || isPendingUpdate) && (
              <Loader title={userToEdit ? "Atualizando Usuário..." : "Registrando Usuário..."} />
            )}
          </form>
        <Button 
          variant="ghost"
          className="md:hidden"
          onClick={() => setIsSheetOpen(false)}
        >
          <ChevronRight className="fixed -left-2 top-1/2 h-6 w-6 text-primary" />
        </Button>

      </SheetContent>
    </Sheet>
  );
};

export default UserForm;
