import {
  LoaderCircle
} from "lucide-react";

const Loader = ({ title }: {title: string}) => {
  return (
    <div className="fixed z-50 top-0 left-0 flex flex-col justify-center bg-white bg-opacity-80 dark:bg-blue-950 dark:bg-opacity-50 items-center h-screen w-screen">
      <LoaderCircle className="animate-spin rounded-full h-16 w-16 text-primary" />
      <p className="text-base text-gray-700">{ title }</p>
    </div>
  );
};

export default Loader;