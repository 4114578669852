import axios from 'axios';

// URL base da API
const BASE_URL = import.meta.env.VITE_BASE_URL;

const api = axios.create({
  baseURL: BASE_URL, // URL do backend
  withCredentials: true, // Habilita o envio de cookies
});

export default api;
