import useVerify from "@/hooks/use-verify";
import InspectionCanvas from "@/components/general-components/InspectionCanvas";
import { useState } from "react";
// import fabricaImage from "@/assets/seguro-de-fabrica-corretora-de-seguros.jpg";
import plantaBaixa from "@/assets/planta-baixa-teste.png";
import { toast } from "@/hooks/use-toast";
import AreaForm from "./AreasForm";
import AreasSearchForm from "./AreasSearchForm";

const ProtectedAreas = () => {
  const { can, has } = useVerify();
  
  const [points, setPoints] = useState([
    { id: '1', name: 'Área 1', x: 0.2, y: 0.3 }, // x: 20%, y: 30% da largura e altura da imagem
    { id: '2', name: 'Área 2', x: 0.4, y: 0.5 }, // x: 40%, y: 50% da largura e altura da imagem
  ]);
  
  // Função chamada ao adicionar um novo ponto
  const handleAddPoint = (newPoint: any) => {
    const newId = (points.length + 1).toString();
    setPoints([...points, { ...newPoint, id: newId }]);
  };
  
  // Função chamada ao clicar em um ponto existente
  const handlePointClick = (point: any) => {
    toast({
      title: "ponto Clicado!",
      description: `Ponto clicado: ID ${point.id}, Coordenadas (${point.x}, ${point.y})`,
      variant: "default",
      duration: 5000
    })
  };

  const handleSearch = async (params: any) => {
    console.log(params);
  };

  const handleClear = () => {
    console.log("clear");
  };
    
  
  if (!can('view_confinus') || !has('Confinus')) return null;
  return (
    <>
      <div className="flex flex-col md:flex-row mb-4 items-start justify-between md:items-center">
        <div>
          <h1 className="text-xl font-bold">Mapa de Área</h1>
          <span className="text-gray-600 dark:text-gray-100">Mapa de zonas de Espaços Confinados</span>
        </div>
      </div>

      <div className="flex justify-between items-center my-4">
        <AreasSearchForm onSubmit={handleSearch} onClear={handleClear} />
        <AreaForm />
      </div>

      <h2 className="my-2 font-medium w-full text-center">Primeiro piso do prédio de Produção:</h2>

      <div className="w-full max-h-96 bg-background overflow-hidden">
        <InspectionCanvas
          imageUrl={plantaBaixa}
          points={points}
          onAddPoint={handleAddPoint}
          onPointClick={handlePointClick}
        />
      </div>
    </>
  );
};

export default ProtectedAreas;