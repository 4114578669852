import {
  FaSquarePersonConfined,
  FaUsersViewfinder,
  FaChalkboardUser,
  // FaWolfPackBattalion
} from "react-icons/fa6";
// import {
//   GiAngelWings,
//   GiWolfHead,
//   GiCentaur,
// } from "react-icons/gi";
import { BsLadder } from "react-icons/bs";
import {
  Vault,
  Rocket,
  ShieldPlus,
  Bolt,
  IdCard,
  Users,
  SquareArrowUp,
  CircleCheckBig,
  ArchiveRestore,
  FileBadge,
  Building,
  ClipboardPlus,
  CalendarCog,
  ChartColumnBig,
  HardHat,
  FilePlus,
  GraduationCap
} from "lucide-react"

export const items = [
  {
    title: "Home",
    icon: <Rocket className="h-4 w-4" />,
    path: "/home",
  },
  {
    title: "Confinus",
    icon: <Bolt  className="h-4 w-4" />,
    path: "/confinus",
    isProduct: "Confinus",
    ability: "confinus",
    subitems: [
      {
        title: "Espaços Confinados",
        icon: <FaSquarePersonConfined className="h-4 w-4" />,
        path: "/confinus/areas-protegidas",
      },
      {
        title: "Dashboard",
        icon: <ChartColumnBig className="h-4 w-4" />,
        path: "/confinus/dashboard",
      },
      {
        title: "Acontrole de Acesso",
        icon: <Vault className="h-4 w-4" />,
        path: "/confinus/access-control",
      },
      {
        title: "Autorizados",
        icon: <IdCard className="h-4 w-4" />,
        path: "/confinus/autorizados",
      },
    ],
  },
  {
    title: "Altus",
    icon: <SquareArrowUp  className="h-4 w-4" />,
    path: "/altus",
    isProduct: "Altus",
    ability: "altus",
    subitems: [
      {
        title: "Trabalhos Altura",
        icon: <BsLadder className="h-4 w-4" />,
        path: "/altus/areas-protegidas",
      },
      {
        title: "Dashboard",
        icon: <ChartColumnBig className="h-4 w-4" />,
        path: "/altus/dashboard",
      },
      {
        title: "Acontrole de Acesso",
        icon: <Vault className="h-4 w-4" />,
        path: "/altus/access-control",
      },
      {
        title: "Autorizados",
        icon: <IdCard className="h-4 w-4" />,
        path: "/altus/autorizados",
      },
    ],
  },
  {
    title: "Protex",
    icon: <ShieldPlus className="h-4 w-4" />,
    path: "/protex",
    isProduct: "Protex",
    ability: "protex",
    subitems: [
      {
        title: "Controle de APIs",
        icon: <HardHat className="h-4 w-4" />,
        path: "/protex/epi-control",
      },
      {
        title: "Estoque EPIs",
        icon: <ArchiveRestore className="h-4 w-4" />,
        path: "/protex/epi-estoque",
      },
    ],
  },
  {
    title: "e-Rdo",
    icon: <ClipboardPlus className="h-4 w-4" />,
    path: "/e-rdo",
    isProduct: "e-Rdo",
    ability: "e-rdo",
    subitems: [
      {
        title: "Diário de Obras",
        icon: <CalendarCog className="h-4 w-4" />,
        path: "/e-rdo/diario",
      },
      {
        title: "Cadastros de Obras",
        icon: <Building className="h-4 w-4" />,
        path: "/e-rdo/obras",
      },
    ],
  },
  {
    title: "Certus",
    icon: <CircleCheckBig className="h-4 w-4" />,
    path: "/certus",
    isProduct: "Certus",
    ability: "certus",
    subitems: [
      {
        title: "Certificados",
        icon: <FileBadge className="h-4 w-4" />,
        path: "/certus/certificados",
      },
      {
        title: "Instrutores",
        icon: <FaChalkboardUser className="h-4 w-4" />,
        path: "/certus/instrutores",
      },
      {
        title: "Turmas",
        icon: <FaUsersViewfinder className="h-4 w-4" />,
        path: "/certus/turmas",
      },
      {
        title: "Provas",
        icon: <FilePlus className="h-4 w-4" />,
        path: "/certus/provas",
      },
      {
        title: "Alunos",
        icon: <GraduationCap className="h-4 w-4" />,
        path: "/certus/alunos",
      },
    ],
  },
  {
    title: "Usuários",
    icon: <Users className="h-4 w-4" />,
    path: "/usuarios",
    ability: "user",
  },
];
