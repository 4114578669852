import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { listPermissions, grantsPermission, revokesPermission } from "@/services/permissionsService";
import { Sheet, SheetContent, SheetHeader, SheetTitle, SheetTrigger } from "@/components/ui/sheet";
import { Label } from "@/components/ui/label";
import { Switch } from "@/components/ui/switch";
import { Button } from "@/components/ui/button";
import { toast as sonner } from "sonner";
import { toast } from "@/hooks/use-toast";
import { ChevronRight, LockKeyholeOpen } from "lucide-react";
import { DialogDescription } from "@/components/ui/dialog";
import { Accordion, AccordionItem, AccordionTrigger, AccordionContent } from "@/components/ui/accordion";
import { useState } from "react";
import Loader from "@/components/general-components/Loader";
import { User } from "@/interfaces/user.interface";
import { Permission, UserPermission } from "@/interfaces/permission.interface";
import { ApiError } from "@/interfaces/api.interface";

const PermissionsForm = ({ user }: { user: User }) => {
  const [isSheetOpen, setIsSheetOpen] = useState(false);
  const [description, setDescription] = useState("");

  const queryClient = useQueryClient();

  // Query de busca de permissões
  const { data: permissionsData, isLoading: isLoadingPermissions, isError, error } = useQuery({ queryKey: ['listPermissions'], queryFn: listPermissions });

  const errorMessage = (error as ApiError)?.response?.data?.message || "Erro ao carregar permissões.";

  const { mutate: grantPermission, isPending: isLoadingGrant } = useMutation({
    mutationFn: (permissionId: number | undefined) => grantsPermission(permissionId, user.id),
    onSuccess: (_data, permissionId) => {
      sonner.success("Permissão Concedida", {
        position: "top-right",
        closeButton: true,
        description: `${user.name} agora pode ${description}.`,
        style: {
          pointerEvents: "auto",
        },
        action: {
          label: "Desfazer",
          onClick: (e) => {
            e.stopPropagation();
            undoGrantPermission(permissionId);
          },
        },
      });
      setDescription("");
      queryClient.invalidateQueries({ queryKey: ["listPermissions"] });
      queryClient.invalidateQueries({ queryKey: ["listCompanyUsers"] });
    },
    onError: (error: unknown) => {
      const err = error as ApiError;
      toast({
        title: "Erro ao conceder permissão!",
        description: `${err.response?.data?.message}`,
        variant: "destructive",
        duration: 5000,
      });
    },
  });

  const { mutate: revokePermission, isPending: isLoadingRevoke } = useMutation({
    mutationFn: (permissionId: number | undefined) => revokesPermission(permissionId, user.id),
    onSuccess: (_data, permissionId) => {
      sonner.warning("Permissão Revogada", {
        position: "top-right",
        closeButton: true,
        description: `${user.name} não pode mais ${description}.`,
        style: {
          pointerEvents: "auto",
        },
        action: {
          label: "Desfazer",
          onClick: (e) => {
            e.stopPropagation();
            undoRevokePermission(permissionId);
          },
        },
      });
      setDescription("");
      queryClient.invalidateQueries({ queryKey: ["listPermissions"] });
      queryClient.invalidateQueries({ queryKey: ["listCompanyUsers"] });
    },
    onError: (error: unknown) => {
      const err = error as ApiError;
      toast({
        title: "Erro ao revogar permissão!",
        description: `${err.response?.data?.message}`,
        variant: "destructive",
        duration: 5000,
      });
    },
  });

  const undoGrantPermission = (permissionId: number | undefined) => {
    revokePermission(permissionId);
  };

  const undoRevokePermission = (permissionId: number | undefined) => {
    grantPermission(permissionId);
  };

  const groupedPermissions = permissionsData?.reduce((acc: Record<string, Permission[]>, permission: Permission) => {
    const group = permission.group || "Outros";
    if (!acc[group]) acc[group] = [];
    acc[group].push(permission);
    return acc;
  }, {});

  return (
    <Sheet open={isSheetOpen} onOpenChange={setIsSheetOpen}>
      <SheetTrigger asChild>
        <Button variant="ghost" className={`flex justify-start p-2 items-baseline w-full h-fit`}>
          <LockKeyholeOpen className="w-3 h-3 mr-2" />
          <p>Permissões</p>
        </Button>
      </SheetTrigger>
      <SheetContent side="right" className="w-full md:w-[400px] overflow-y-auto">
        <SheetHeader>
          <SheetTitle>Controle de Permissões</SheetTitle>
          <DialogDescription>
            Selecione as permissões necessárias para
            <strong className="pl-1">{user.name}</strong>.
          </DialogDescription>
        </SheetHeader>
        {isLoadingPermissions ? (
          <Loader title="Carregando permissões..." />
        ) : permissionsData?.length > 0 ? (
          <Accordion type="multiple" className="mt-4">
            {Object.entries(groupedPermissions || {}).map(([groupName, permissions]) => (
              <AccordionItem key={groupName} value={groupName}>
                <AccordionTrigger>{groupName}</AccordionTrigger>
                <AccordionContent>
                  <ul className="flex flex-wrap gap-2">
                    {(permissions as Permission[])?.map((permission: Permission) => (
                      <PermissionItem
                        key={permission.id}
                        permission={permission}
                        user={user}
                        isGranted={user.permissions?.some((p: UserPermission) => p.permissionId === permission.id)}
                        onGrant={() => grantPermission(permission.id)}
                        onRevoke={() => revokePermission(permission.id)}
                        setDescription={setDescription}
                      />
                    ))}
                  </ul>
                </AccordionContent>
              </AccordionItem>
            ))}
          </Accordion>
        ) : isError && (
          <p className="text-red-500 my-4">Erro ao carregar permissões: {errorMessage}</p>
        )}
        {isLoadingGrant && <Loader title="Concedendo permissão..." />}
        {isLoadingRevoke && <Loader title="Revogando permissão..." />}
        <Button variant="ghost" className="md:hidden" onClick={() => setIsSheetOpen(false)}>
          <ChevronRight className="fixed -left-2 top-1/2 h-6 w-6 text-primary" />
        </Button>
      </SheetContent>
    </Sheet>
  );
};

const PermissionItem = ({
  permission,
  isGranted,
  onGrant,
  onRevoke,
  setDescription,
}: {
  permission: Permission;
  user: User;
  isGranted: boolean;
  onGrant: () => void;
  onRevoke: () => void;
  setDescription: (description: string) => void;
}) => {
  return (
    <li className="flex items-center gap-2">
      <Switch
        id={permission.name}
        checked={isGranted}
        onCheckedChange={() => {
          if (isGranted) {
            onRevoke();
          } else {
            onGrant();
          }
          setDescription(permission.description);
        }}
      />
      <Label htmlFor={permission.name}>{permission.description}</Label>
    </li>
  );
};

export default PermissionsForm;
