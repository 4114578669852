import api from './api';
import { User } from '@/interfaces/user.interface';

// URL base da API
const BASE_URL = import.meta.env.VITE_BASE_URL || "http://localhost:3000";
const accessToken = localStorage.getItem('accessToken');

// Função para criar o FormData a partir do objeto User
const createFormData = (user: User): FormData => {
  const formData = new FormData();
  formData.append('companyId', String(user.companyId));
  formData.append('cpf', user.cpf);
  formData.append('email', user.email);
  formData.append('name', user.name);
  if (user.password) formData.append('password', String(user.password));
  formData.append('phone', user.phone);
  formData.append('roleId', String(user.roleId));

  // Adiciona a imagem, se existir
  if (user.image) {
    formData.append('image', user.image);
  } else {
    if (user.imageUrl) {
      formData.append('imageUrl', user.imageUrl);
    }
  }
  return formData;
};

// Create User
const createUser = async (user: User) => {
  const formData = createFormData(user);

  const response = await api.post(`${BASE_URL}/user`, formData, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'multipart/form-data',
    },
  });
  return response.data;
};

// Update User
const updateUser = async (user: User, userId: number | undefined) => {
  const formData = createFormData(user);

  const response = await api.put(`${BASE_URL}/user/update/${userId}`, formData, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'multipart/form-data',
    },
  });
  return response.data;
};

// Função para buscar usuários da empresa
const listUsersCompany = async (params: {
  name?: string;
  active?: boolean;
  cpf?: string;
  createdAt?: string;
  limit?: number;
  pagination?: number;
  roleId?: number;
}) => {
  const { name, active, cpf, createdAt, roleId, limit, pagination } = params;

  const formatToISOString = (date: string | Date, isEndOfDay = false) => {
    const parsedDate = date instanceof Date ? date : new Date(date);
    if (isEndOfDay) {
      parsedDate.setHours(23, 59, 59, 999);
    } else {
      parsedDate.setHours(0, 0, 0, 0);
    }
    return parsedDate.toISOString();
  };

  // Montando os query params dinamicamente
  const queryParams = new URLSearchParams();

  if (limit) queryParams.append('limit', limit.toString());
  if (pagination) queryParams.append('pagination', pagination.toString());
  if (name) queryParams.append('name', name);
  if (active !== undefined) queryParams.append('active', active.toString());
  if (cpf) queryParams.append('cpf', cpf.toString());
  if (createdAt && createdAt?.length > 1) {
    queryParams.append('startedAt', formatToISOString(createdAt[0]));
    queryParams.append('endedAt', formatToISOString(createdAt[1], true));
  };
  if (createdAt?.length === 1) queryParams.append('startedAt', formatToISOString(createdAt[0]));
  if (roleId) queryParams.append('roleId', roleId.toString());

  const response = await api.get(`${BASE_URL}/user/list?${queryParams.toString()}`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });

  return response.data;
};

// pega o próprio usuário
const getSelf = async () => {
  const response = await api.get(`${BASE_URL}/user/self`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
  return response.data;
};

// Edita o próprio usuário
const updateSelf = async (user: User) => {
  const formData = createFormData(user);

  const response = await api.put(`${BASE_URL}/user/update/self`, formData, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'multipart/form-data',
    },
  });
  return response.data;
};

// Inactive User
const inactiveUser = async (userId: number) => {
  const response = await api.patch(`${BASE_URL}/user/inactive/${userId}`, {}, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
  return response.data;
};

// Active User
const activeUser = async (userId: number) => {
  const response = await api.patch(`${BASE_URL}/user/activate/${userId}`, {}, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
  return response.data;
};

export {
  listUsersCompany,
  inactiveUser,
  activeUser,
  createUser,
  updateUser,
  getSelf,
  updateSelf,
};
