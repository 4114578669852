import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogDescription, DialogFooter } from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";

interface ConfirmDialogProps {
  isOpen: boolean;
  setIsOpen: (open: boolean) => void;
  title: string;
  description: string;
  onConfirm: () => void;
  isLoading?: boolean;
  variant?: "default" | "destructive";
}

const ConfirmDialog: React.FC<ConfirmDialogProps> = ({
  isOpen,
  setIsOpen,
  title,
  description,
  onConfirm,
  isLoading = false,
  variant = "default",
}) => {
  const handleClose = () => {
    setIsOpen(false);
  };

  const handleConfirm = () => {
    onConfirm();
    setIsOpen(false); // Garante que o diálogo será fechado após confirmação
  };

  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <DialogContent
        className="max-w-lg mx-auto"
        onPointerDownOutside={handleClose} // Fecha ao clicar fora do modal
        onEscapeKeyDown={handleClose} // Fecha ao pressionar ESC
      >
        <DialogHeader>
          <DialogTitle>{title}</DialogTitle>
          <DialogDescription>{description}</DialogDescription>
        </DialogHeader>
        <DialogFooter>
          <Button
            variant="outline"
            className="rounded"
            onClick={handleClose}
          >
            Cancelar
          </Button>
          <Button
            variant={variant}
            className="rounded"
            onClick={handleConfirm}
            disabled={isLoading}
          >
            {isLoading ? "Processando..." : "Confirmar"}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default ConfirmDialog;
