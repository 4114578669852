"use client";

import { useState } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { Button } from "@/components/ui/button";
import { Sheet, SheetContent, SheetHeader, SheetTitle, SheetTrigger } from "@/components/ui/sheet";
import { Form, FormLabel } from "@/components/ui/form";
import { Select, SelectTrigger, SelectValue, SelectContent, SelectItem } from "@/components/ui/select";
import { ChevronLeft, Map } from "lucide-react";
import { DialogDescription } from "@/components/ui/dialog";

const userSearchSchema = z.object({
  name: z.string().optional(),
  active: z.boolean().optional(),
  cpf: z.string().optional(),
  roleId: z.number().optional(),
  createdAt: z.tuple([z.date().optional(), z.date().optional()]).optional(),
});

type UserSearchFormData = z.infer<typeof userSearchSchema>;

interface UserSearchFormProps {
  onSubmit: (data: UserSearchFormData) => void;
  onClear: () => void;
}

const AreasSearchForm: React.FC<UserSearchFormProps> = ({ onSubmit }) => {
  const [isSheetOpen, setIsSheetOpen] = useState(false);

  const form = useForm<UserSearchFormData>({
    resolver: zodResolver(userSearchSchema),
    defaultValues: {
      name: "",
      active: undefined,
      cpf: "",
      roleId: undefined,
      createdAt: undefined,
    },
  });

  const handleStatusChange = (value: string) => {
    form.setValue("active",  value === "true" ? true : false);
  };

  return (
    <Sheet open={isSheetOpen} onOpenChange={setIsSheetOpen}>
      <SheetTrigger asChild>
        <Button variant="default" className="text-muted h-9 bg-primary flex items-center">
          <Map className="w-3 h-3 md:mr-2" />
          <span className="hidden md:block">Selecione Área</span>
        </Button>
      </SheetTrigger>
      <SheetContent side="left" className="w-full md:w-[300px] p-6">
        <SheetHeader>
          <SheetTitle>Selecionar Área</SheetTitle>
        </SheetHeader>
        <DialogDescription className="mb-4">
            Selecione a Área que deseja visualizar.
        </DialogDescription>
        <Form {...form}>
          <form
            onSubmit={form.handleSubmit((data) => {
            
              onSubmit(data);
              setIsSheetOpen(false);
            })}
            className="space-y-4"
          >
            
            {/* Status */}
            <div>
              <FormLabel htmlFor="active">Áreas</FormLabel>
              <Select onValueChange={handleStatusChange} value={form.watch("active")?.toString() || ""}>
                <SelectTrigger className="mt-1">
                  <SelectValue placeholder="Selecione status" />
                </SelectTrigger>
                <SelectContent>
                  <SelectItem value="true">Ativo</SelectItem>
                  <SelectItem value="false">Inativo</SelectItem>
                </SelectContent>
              </Select>
            </div>
           
             {/* Botões */}
             <div className="flex w-full space-x-2">
              <Button className="w-full" type="submit">
                Buscar
              </Button>
              {/* <Button
                type="button"
                variant="outline"
                className="w-1/2"
                onClick={() => {
                  form.reset();
                  setStartDate(undefined);
                  setEndDate(undefined);
                  onClear();
                  setIsSheetOpen(false);
                }}
              >
                Limpar
              </Button> */}
            </div>
          </form>
        </Form>
        <Button 
          variant="ghost"
          className="md:hidden"
          onClick={() => setIsSheetOpen(false)}
        >
          <ChevronLeft className="fixed -right-2 top-1/2 h-6 w-6 text-primary" />
        </Button>
      </SheetContent>
    </Sheet>
  );
};

export default AreasSearchForm;
