import React, { useState, useEffect } from "react";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { z } from "zod";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { toast } from "@/hooks/use-toast";
import Loader from "@/components/general-components/Loader";
import DropUpload from "@/components/general-components/DropUpload";
import { getSelf, updateSelf } from "@/services/userService"; // Importa as funções
import { formatCPF, unformatCPF } from "@/utils/cpf-mask";

const Profile = () => {
  const queryClient = useQueryClient();

  // Validação do formulário com Zod
  const userSchema = z.object({
    id: z.number(),
    name: z.string().min(2, { message: "Nome deve ter pelo menos 2 caracteres" }),
    email: z.string().email({ message: "Email inválido" }),
    phone: z.string().min(10, { message: "Telefone deve ter pelo menos 10 dígitos" }),
    cpf: z.string().length(11, { message: "CPF deve ter 11 dígitos" }),
    password: z.string().optional(),
    roleId: z.number(),
    companyId: z.number(),
    imageUrl: z.string().nullable(),
    image: z.instanceof(File).nullable(),
  });

  type UserFormData = z.infer<typeof userSchema>;

  const [formData, setFormData] = useState<UserFormData>({
    id: 0,
    name: "",
    email: "",
    phone: "",
    cpf: "",
    password: "",
    roleId: 4,
    companyId: 0,
    image: null,
    imageUrl: null,
  });

  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const [preview, setPreview] = useState<string | null>(null);

  const { data: usersData, isLoading: isLoadingSelf } = useQuery({
    queryKey: ['selfUser'],
    queryFn: () => getSelf(),
  });

  // Efeito para configurar o formulário com os dados retornados pela query
  useEffect(() => {
    if (usersData?.users.length) {
      setFormData({
        id: usersData?.users[0]?.id || 0,
        name: usersData?.users[0]?.name || "",
        email: usersData?.users[0]?.email || "",
        phone: usersData?.users[0]?.phone || "",
        cpf: usersData?.users[0]?.cpf || "",
        roleId: usersData?.users[0]?.roleId || 4,
        companyId: usersData?.users[0]?.companyId || 0,
        password: "",
        image: null,
        imageUrl: usersData?.users[0]?.imageUrl || null,
      });
      if (usersData?.users[0]?.imageUrl) {
        setPreview(usersData?.users[0]?.imageUrl);
      }
    }
  }, [usersData]);

  // Mutation para atualizar o próprio usuário
  const { mutate: updateProfile, isPending: isUpdating } = useMutation({
    mutationFn: updateSelf,
    onSuccess: () => {
      toast({
        title: "Perfil atualizado!",
        description: "Suas informações foram atualizadas com sucesso.",
        variant: "success",
      });
      queryClient.invalidateQueries({ queryKey: ["selfUser"] });
    },
    onError: (error: any) => {
      toast({
        title: "Erro ao atualizar perfil",
        description: error.response?.data?.message || "Erro desconhecido.",
        variant: "destructive",
      });
    },
  });

  const handleChange = (name: string, value: string | number) => {
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    const result = userSchema.safeParse(formData);

    if (!result.success) {
      const formattedErrors: any = result.error.format();
      const newErrors: { [key: string]: string } = {};
      for (const key in formattedErrors) {
        if (key !== "_errors") {
          newErrors[key] = formattedErrors[key]?._errors[0] || "";
        }
      }
      setErrors(newErrors);
      return;
    }

    updateProfile(formData);
  };

  if (isLoadingSelf) {
    return <Loader title="Carregando perfil..." />;
  }

  return (
    <>
      <div className="flex flex-col md:flex-row mb-4 items-start justify-between md:items-center">
        <div>
          <h1 className="text-xl font-bold">Meu Perfil</h1>
          <span className="text-gray-600 dark:text-gray-100">Atualizar meus dados</span>
        </div>
      </div>
      <div className="flex flex-col items-center gap-2 mt-4">
        <form onSubmit={handleSubmit} className="flex flex-col gap-2 mt-4 w-full md:w-1/2">
          <div className="w-full mx-auto md:w-1/2">
            <DropUpload setImage={setFormData} EditPreview={preview} />
          </div>
          <div>
            <Label htmlFor="name">Nome</Label>
            <Input
              id="name"
              name="name"
              placeholder="Digite seu nome"
              value={formData.name}
              onChange={(e) => handleChange(e.target.name, e.target.value)}
              className="mt-1"
            />
            {errors.name && <p className="text-red-500 text-sm">{errors.name}</p>}
          </div>
          <div>
            <Label htmlFor="email">Email</Label>
            <Input
              id="email"
              name="email"
              type="email"
              placeholder="Digite seu email"
              value={formData.email}
              onChange={(e) => handleChange(e.target.name, e.target.value)}
              className="mt-1"
            />
            {errors.email && <p className="text-red-500 text-sm">{errors.email}</p>}
          </div>
          <div>
            <Label htmlFor="password">Alterar Senha</Label>
            <Input
              id="password"
              name="password"
              type="password"
              placeholder="Digite nova senha"
              value={formData.password}
              onChange={(e) => handleChange(e.target.name, e.target.value)}
              className="mt-1"
            />
            {errors.password && <p className="text-red-500 text-sm">{errors.password}</p>}
          </div>
          <div>
            <Label htmlFor="phone">Telefone</Label>
            <Input
              id="phone"
              name="phone"
              placeholder="Digite seu telefone"
              value={formData.phone}
              onChange={(e) => handleChange(e.target.name, e.target.value)}
              className="mt-1"
            />
            {errors.phone && <p className="text-red-500 text-sm">{errors.phone}</p>}
          </div>
          <div>
            <Label htmlFor="cpf">CPF</Label>
            <Input
              id="cpf"
              name="cpf"
              placeholder="Digite seu CPF"
              value={formatCPF(formData.cpf)}
              onChange={(e) => handleChange(e.target.name, unformatCPF(e.target.value))}
              className="mt-1"
            />
            {errors.cpf && <p className="text-red-500 text-sm">{errors.cpf}</p>}
          </div>
          <Button
            type="submit"
            className="w-full my-4"
            disabled={isUpdating}
          >
            {isUpdating ? "Atualizando..." : "Atualizar Perfil"}
          </Button>
          {isUpdating && <Loader title="Atualizando perfil..." />}
        </form>
      </div>
    </>
  );
};

export default Profile;
